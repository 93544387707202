import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'

export default function TermsOfService() {
  return (
    <>
      <Helmet>
        <title>Discovemail - Terms of service</title>
      </Helmet>

      <Navbar />

      <section className='termsOfService bg_color_dark mt-md-5 py-md-5'>
        <div className=' container py-md-5'>
          <div className='row px-md-4'>
            <div className='col-12 termsOfServiceCard bg_color_dark2 p-md-5 p-3'>
              <h1 className="linearGradientTitle"> Terms of Service</h1>
              <hr className='horizontalLine'/>
              <p className='description'>
                The following terms and conditions govern all use of the Discovemail Web Services, Inc. ("Discovemail”) website and all content, services and products available at, or through, the Discovemail domain discovemail.com (the “Website”), including, but not limited to, the Discovemail user management service (collectively with the Website, the “Services”). The Services are offered subject to your acceptance, without modification, of all of the terms and conditions contained herein and all other operating rules, policies, and procedures that may be published from time to time on this Website by Discovemail (collectively, the "Agreement").
                Please read this Agreement carefully before accessing or using the Services. By accessing or using any part of the Website, you agree to be bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you may not access or use the Services. If these terms and conditions are considered an offer by Discovemail, acceptance is expressly limited to these terms. The Services are available only to individuals who are at least 18 years old.
              </p>

              <h2 className='title mt-md-5'>Your Discovemail Account</h2>
              <p className='description'>
                If you create an account for the Services, you are responsible for maintaining the security of your account, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You must immediately notify Discovemail of any unauthorized uses of your account or any other breaches of security. Discovemail will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. You must be a human. Accounts registered by "bots" or other automated methods are not permitted. You must provide your legal full name, a valid email address, and any other information requested in order to complete the sign-up process. You must not, in the use of the Services, violate any laws in your jurisdiction and in the United States (including but not limited to copyright or trademark laws). You must not create more than one account. You must not share your account with others.
              </p>

              <h2 className='title mt-md-5'>Disclosure</h2>
              <p className='description'>
                Our application fully complies with the Google API Services User Data Policy. This includes strict adherence to the Limited Use requirements outlined in the policy. All user data accessed through Google APIs is utilized solely for the intended purposes within our app's functionalities. For easy reference and accessibility, you can find the complete Google API Services User Data Policy by following this <a href="https://developers.google.com/terms/api-services-user-data-policy" target='_blank' rel="noreferrer">Google API Services User Data Policy</a>. We encourage all users to review this policy to understand how their data is handled within our app.
              </p>
            </div>


            <div className='col-12 termsOfServiceCard bg_color_dark2 p-md-5 p-3 mt-4'>
              <h4 className='linearGradientTitle'>General Terms</h4>
              <hr className='horizontalLine' />
              <h2 className='title'>Payment and Renewal</h2>
              <p className='description'>
                Optional paid services, such as extra capacity, are available on the Services (any such optional services, an "Upgrade"). By selecting an Upgrade, you agree to pay Discovemail the monthly or annual subscription fees indicated for that Upgrade (additional payment terms are described below). Payments will be charged on a prepaid basis on the day you sign up for an Upgrade and will cover the use of that Upgrade for a monthly or annual subscription period, as requested by you. Upgrade fees are non-refundable. To prevent fraud, we might temporarily disable your account after an upgrade to perform a manual verification.
                Automatic Renewal Unless you notify Discovemail before the end of the applicable subscription period that you want to cancel an Upgrade, your Upgrade subscription will automatically renew, and you authorize us to collect the then-applicable annual or monthly subscription fee for such Upgrade (as well as any applicable taxes) using any credit card or other payment mechanism we have on record for you.
              </p>

              <h2 className='title mt-md-5'>Discovemail Fees and payment</h2>
              <p className='description'>
                By signing up for a Discovemail account, you agree to pay Discovemail the monthly or annual subscription fees published on the Website as of when you registered for the Services. Applicable fees will be invoiced starting from the day your paid account is established and in advance of using the paid Services. Your monthly or annual subscription will automatically renew unless you cancel it. Discovemail reserves the right to change the payment terms and fees upon thirty (30) days prior written notice to you. Unless mentioned otherwise, the prices do not include taxes. Discovemail might change the amount charged for an ongoing subscription if we're required by law to collect new taxes.
                Cancellations and Refunds Discovemail does not provide refunds to users who request cancellation of Services, regardless of the reason for the request. You may cancel your account at any time via email to Discovemail or in the Settings section of your account's dashboard. If you cancel paid Services, the cancellation will take effect at the end of your current billing period. Thereafter, Discovemail will cease charging you for paid services and will disable your paid account. Support The Services includes access to email support. "Email support" means the ability to make requests for technical support by email at any time (with reasonable efforts by Discovemail to respond within three business days) concerning the use of the Services. All Discovemail support will be provided in accordance with Discovemail standard practices, procedures, and policies.
              </p>

              <h2 className='title mt-md-5'>Discovemail API</h2>
              <p className='description'>
                Any use of the Discovemail application programming interface (the “API”), including use of the API through a third-party product that accesses the Services, must comply with this Agreement including the following terms:
                Damage and liability You expressly understand and agree that Discovemail shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to: damages for loss of profits, goodwill, use, data or other intangible losses (even if Discovemail has been advised of the possibility of such damages), resulting from your use of the API or third-party products that access data via the API.
                Rate limiting Abuse or excessively frequent requests to Discovemail via the API may result in the temporary or permanent suspension of your account's access to the API. Discovemail, in its sole discretion, will determine abuse or excessive usage of the API. Discovemail, in its sole discretion, may elect to warn the account owner prior to suspension. Discontinuation Discovemail reserves the right at any time to modify or discontinue, temporarily or permanently, your access to the API (or any part thereof) with or without notice, for any or no reason.
              </p>

              <h2 className='title mt-md-5'>Marketing</h2>
              <p className='description'>
                By creating an account, you grant Discovemail a perpetual worldwide license to use your company's name and logo(s) for the sole purpose of Discovemail's marketing and sales efforts, such as listing you as a Discovemail customer on the Website. You may revoke this license at any time by notifying Discovemail via email.
              </p>

              <h2 className='title mt-md-5'>Copyright Infringement</h2>
              <p className='description'>
                As Discovemail asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to by Discovemail violates your copyright, you are encouraged to notify Discovemail. Discovemail will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. Discovemail will terminate a visitor's access to and use of the Services if, under appropriate circumstances, the visitor is determined to be a repeat infringer of the copyrights or other intellectual property rights of Discovemail or others.
              </p>

              <h2 className='title mt-md-5'>Intellectual Property</h2>
              <p className='description'>Discovemail does not transfer to you any Discovemail or third-party intellectual property, and all right, title and interest in and to such property will remain (as between the parties) solely with Discovemail. Discovemail, the Discovemail logo, and all other trademarks, service marks, graphics and logos used in connection with Discovemail, or the Services are trademarks or registered trademarks of Discovemail or Discovemail licensors. Other trademarks, service marks, graphics and logos used in connection with the Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any Discovemail or third-party trademarks.</p>

              <h2 className='title mt-md-5'>Use License</h2>
              <p className='description'>Under this license you may not:</p>
              <p className='description'>Build a similar or competitive service without Discovemail’s prior written consent. In any way violate any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).
                Exploit, harm, or attempt to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise. Send, knowingly receive, upload, download, use, or re-use any material that does not comply with this Agreement. Impersonate or attempt to impersonate Discovemail, a Discovemail employee, another user, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing). Engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm Discovemail or users of the Website or expose them to liability.</p>

              <h2 className='title mt-md-5'>Additionally, you agree not to:</h2>
              <p className='description'>
                Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website. Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without Discovemail’s prior written consent. Use any device, software, or routine that interferes with the proper working of the Website. Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website. Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
                Attempt to decompile or reverse engineer any software contained on the Website or API. Otherwise attempt to interfere with the proper working of the Website.
                Your license to use the Services shall automatically terminate if you violate any of these prohibitions or restrictions and may be terminated by Discovemail at any time, in its sole discretion.
              </p>

              <h2 className='title mt-md-5'>Changes</h2>
              <p className='description'>
                Discovemail reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Services following the posting of any changes to this Agreement constitutes acceptance of those changes. Discovemail may also, in the future, offer new services and/or features through the Services (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.
              </p>

              <h2 className='title mt-md-5'>Termination</h2>
              <p className='description'>Discovemail may terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately. In the case of a paid account, Discovemail will refund the unused portion of your prepaid subscription amount within thirty (30) days, which will be paid to the same payment method you used to open the account. If you wish to terminate this Agreement, you may simply discontinue using the Services.</p>

              <h2 className='title mt-md-5'>Disclaimer of Warranties</h2>
              <p className='description'>
                The Services are provided “as is”. Discovemail and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Discovemail nor its suppliers and licensors, makes any warranty that the Services will be error-free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Services at your own discretion and risk.
              </p>

              <h2 className='title mt-md-5'>Limitation of Liability</h2>
              <p className='description'>
                In no event will Discovemail, or its suppliers or licensors, be liable with respect to any subject matter of this Agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to Discovemail under this Agreement during the twelve (12) month period prior to the cause of action. Discovemail shall have no liability for any failure or delay due to matters beyond or not within their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
              </p>

              <h2 className='title mt-md-5'>General Representation and Warranty</h2>
              <p className='description'>
                You represent and warrant that (i) your use of the Services will be in strict accordance with the Discovemail Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside) and (ii) your use of the Services will not infringe or misappropriate the intellectual property rights of any third party.
              </p>

              <h2 className='title mt-md-5'>Indemnification</h2>
              <p className='description'>You agree to indemnify and hold harmless Discovemail, its contractors, its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Services, including but not limited to your violation of this Agreement.</p>

              <h2 className='title mt-md-5'>Governing Law</h2>
              <p className='description'>All matters relating to the Website and these Agreement and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).</p>

              <h2 className='title mt-md-5'>Additional notices for certain jurisdictions</h2>
              <h4 className='title mt-md-4'>India</h4>
              <p className='description'>Those terms apply exclusively to India residents and businesses incorporated in India. They are an addition to the standard Terms of Service.
                Limitation to the purchase of premium plans. Paid plans or products on Discovemail are only available to businesses. Consumers should not attempt to perform a purchase on Discovemail. </p>

              <p className='title'>Our address:</p>
              <address className='description mb-0'>
                #265, 2nd Floor, 6th Main, <br />
                4th Cross Rd, Stage 2, BTM Layout, <br />
                Bengaluru, Karnataka 560076
              </address>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
