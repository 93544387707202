import React, { useState } from "react";
import NavbarDash from "../pages/dashboard/Navbar";
import { priceCardData } from "../resource/data/priceCardData";
import { MdDone, MdClose } from "react-icons/md";
import requestInstance from "../services/request";
import { API_ENDPOINT } from "../services/Api";
import { loadStripe } from '@stripe/stripe-js';
import { toast } from "react-toastify";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import Navbar from "./Navbar_1";
import EmailCards from "../home_revamp/EmailCards";
import Faq from "../home_revamp/Faq";
import NewFooter from "../home_revamp/NewFooter";
import NewPricePlan from "../home_revamp/NewPricePlan";
import Elevate from "../home_revamp/Elevate";
import PricingFrame from "../assets/revampHomeassets/PricingFrame.svg"

function NewPrice() {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('find_email');
  const navigate = useNavigate();

  const handlePayment = async (val) => {
    if (token?.length > 0) {
      setLoading(true);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

      const body = {
        "plan_type": val,
      }

      requestInstance
        .post(API_ENDPOINT.STRIPE_PAYMENT_CHECKOUT, body)
        .then((res) => {
          const session = res?.data?.data;
          const result = stripe.redirectToCheckout({ sessionId: session.sessionId })
          if (result.error) {
            console.log(result.error);
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    } else {
      navigate("/signin");
    }
  }

  // const generalQuestion = [
  //   {
  //     id: 1,
  //     title: "What is the best time to use 1 credit on Discovemail.com?",
  //     description:
  //       "Each email request costs one credit on DiscoveMail.com. You can sign up for the 100% free plan at Discovemail.com.You can select one of our paying plans if you want some of the more advanced features. Our goal is to assist as many individuals as we can in meeting their prospecting needs without trying to take advantage of them financially.",
  //   },
  //   {
  //     id: 2,
  //     title: "What is the accuracy of Discovemail Email Finder ?",
  //     description:
  //       "The accuracy of Discovemail Email Finder can vary, but it's designed to provide reliable results by leveraging multiple data sources and advanced algorithms.Many users find it to be an efficient tool for discovering email addresses, especially for outreach and lead generation.However, as with any email finder tool, it's important to verify email addresses for accuracy before using them for communication to ensure successful outreach.",
  //   },
  //   {
  //     id: 3,
  //     title: "Are the email addresses guesses or found somewhere ?",
  //     description:
  //       "Email addresses found using an email finder tool like Discovemail are typically not guesses. Instead, these tools search for email addresses that are publicly available or have been published on websites, social media profiles, online directories, or other publicly accessible sources. The process typically involves web scraping and data mining techniques to locate and compile email addresses from various online sources. It doesn't involve random guessing of email addresses, as that would be highly inefficient and unlikely to yield accurate results.",
  //   },

  // ];

  // const generalQuestion2 = [
  //   {
  //     id: 4,
  //     title: "Can I find the email addresses of a list of people ?",
  //     description:
  //       "Yes, We have a bulk email verification option to verify email addresses. You have to just upload the file and press enter it will validate and give the results.",
  //   },
  //   {
  //     id: 5,
  //     title: "How is the Discovemail different from other email search tools ?",
  //     description:
  //       "The Discovemail automates email searches using various data sources, making it efficient for bulk searches and potentially offering email verification, integration, and privacy features that distinguish it from manual email search methods or other tools.",
  //   },
  //   {
  //     id: 6,
  //     title: "Does Discovemail send out any e-mail message while email verify?",
  //     description:
  //       "No, Discovemail doesn't send out any e-mail message while email verify",
  //   },

  // ];

  var settings = {

    infinite: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const [hover, setHover] = useState({
    id: priceCardData[0].id,
  });

  const MouseOver = (e, ele) => {
    setHover({
      id: ele.id,
    });
  };

  const MouseOut = (e, ele) => {
    setHover({
      id: ele.id,
    });
  };

  const handleRedirect = (type) => {
    window.open(window.location.origin + `/select-plan?plan=${type}`, "_blank");
  };

  const user = JSON.parse(localStorage.getItem("find_email"));
  return (
    <div className="">
      {token?.length > 0 ? <NavbarDash /> : <Navbar/>}
      <div className="pricingHero heroTopPadding pb-xxl-5 ">
        <h1 className="text-white py-xxl-5 text-center fs-1 ">Choose the perfect plan to fit your needs and budget.</h1>
      </div>
      <EmailCards />
      <NewPricePlan />
      <Elevate />
      <Faq />
      <NewFooter />
    </div>
  );
}

export default NewPrice;
