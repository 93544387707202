import React, { useEffect } from "react";
import "./NewSignIn.css";
import signInBanner from '../../assets/sign_In_pic.webp'
import DiscoveLogo from '../../assets/discovemail_logo.png'
import PageNavbar from "./PageNavbar";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useState } from "react";
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { RiKey2Line } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import Helmet from "react-helmet";
// import NewPageNav from "./NewPageNavbar";
import Navbar from "../../new_home/Navbar_1";
import ContactUsSection from "../../components/contact/ContactUsSection";
import { useDispatch } from "react-redux";
import { googleAuth, googleSign } from "../../redux/slices/googleAuth/GoogleAuthSlice";
import GoogleBtn from '../../assets/btn_google_signin_dark_normal_web.png';
import GoogleBtnPressed from '../../assets/btn_google_signin_dark_pressed_web.png';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Please enter the password";
  } else if (!/^(?=.*?[a-z])/i.test(values.password)) {
    errors.password = "Must contain lowercase";
  } else if (!/^(?=.*?[0-9])/i.test(values.password)) {
    errors.password = "Must contain number";
  } else if (!/.{4,}/i.test(values.password)) {
    errors.password = "Must have 4 characters";
  }

  return errors;
};

function NewSignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(GoogleBtn);
  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    if (searchParams.get("code")) {
      requestInstance.post(API_ENDPOINT.GOOGLE_SIGN_IN_VERIFY, {
        "code": searchParams.get("code"),
        "state": searchParams.get("state")
      }).then((res) => {
        setCurrentUser(res?.data?.access);
        toast.success(res?.data?.status);
        navigate("/dashboard");
      }).catch((err) => {
        console.log(err);
      })
    }
  }, []);

  const handleGoogleSignIn = async () => {
    setCurrentImage(GoogleBtnPressed);

    try {
      const response = await requestInstance.get(API_ENDPOINT.GOOGLE_SIGN_IN);
      window.open(response?.data?.url, '_self');
    } catch (error) {
      console.log(error);
    }
  }

  function togglePasswordVisibility() {
    setHidePassword(!hidePassword);
  }

  const handleClickSignUp = () => {
    navigate("/signup");
  };

  const verifyUser = () => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SIGNATURE_API)
      .then((res) => {
        if (res.data.status !== 'fail') {
          dispatch(googleAuth(true));
          dispatch(googleSign(res.data.data))
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("find_email"));
    if (user) {
      navigate("/dashboard");
    }
    window.scroll(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (login) => {
      setLoading(true);
      setButtonDisabled(true);
      handleSignIn(login);
    },
  });

  const handleSignIn = (login) => {
    let value = {
      email: login.email,
      password: login.password,
    };

    requestInstance
      .post(API_ENDPOINT.LOGIN_API, value)
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          setCurrentUser(res.data.access);
          toast.success(res.data.status);
          verifyUser();
          // console.log(res.data) 
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const TITLE = "Discovemail-Login";
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {/* <NewPageNav /> */}

      <section className="loginSection container-fluid px-0 mx-0 bg_color_dark">
        <div className="row px-0 mx-0 align-items-center">
          <div className="col-md-6 px-0 position-relative">
            <div>
              <img src={signInBanner} alt="img not found" className="img-fluid" />
            </div>
            <div className="logo">
              <a href="/">
                <img src={DiscoveLogo} alt="img not found" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="col-md-6   ">
            <div className="row justify-content-center py-5 px-md-5">
              <div className="col-10">
                <h1 className="loginTitle text-center mb-4">Welcome back</h1>
                {/* <button className="btn btn-outline-light col-12 mx-sm-0 py-2">
                  <FcGoogle />&nbsp; Continue with google
                </button>
                <div class="orText text-center my-lg-4 my-3">
                  <span>Or</span>
                </div> */}
                <div className="">
                  <form onSubmit={formik.handleSubmit} className="">
                    <div className="form-label mb-lg-4 mt-3 mb-3">
                      <div className="input-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className="form-control py-3"
                          placeholder="Enter email address"
                        />
                      </div>

                      {formik.touched.email && formik.errors.email ? (
                        <div className="errormsg">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div className="input-group">
                      <input
                        type={hidePassword ? "password" : "text"}
                        id="password"
                        name="password"
                        title="Password must contain atleast 4 characters and atleast 1 number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className="form-control password py-3"
                        placeholder="Password"
                      />
                      <span className="input-group-text login_icon" onClick={togglePasswordVisibility}>
                        {" "}
                        {hidePassword ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </span>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="errormsg">{formik.errors.password}</div>
                    ) : null}
                    <div className="d-flex justify-content-end my-3">
                      <a href="/forget" className="text-decoration-none login_forget">
                        <span>Forgot Password?</span>
                      </a>
                    </div>

                    <div>
                      <button
                        className="btn btn-disco w-100 py-3 mb-3"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Loader /> : <>Login</>}
                      </button>
                    </div>

                    <p className="text-decoration-none text-center signUpTxt">
                      Don't have an Account?
                      <span
                        onClick={handleClickSignUp}
                        role="button"
                        className="fw-bold login_navigation ms-2"
                      >
                        SignUp
                      </span>
                    </p>
                  </form>
                </div>
                <div className='text-center' style={{ color: "#ccc"}}>OR</div>
                <div className="w-100 d-flex justify-content-center mt-3 cursorPointer">
                  <img onClick={handleGoogleSignIn} src={currentImage} alt="Sign in with Google" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default NewSignIn;
