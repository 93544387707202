import React, { useEffect, useState } from "react"
import Instagram from '../assets/instagram-icon.svg';
import Linkedin from '../assets/linkedin-icon.svg';
import DiscoveLogo from '../assets/discovemail_logo.png'
import DiscoveBg from '../assets/revampHomeassets/DiscoveBg.svg';
import DiscoveBg2 from '../assets/revampHomeassets/DiscoveBg2.svg';
import "../new_home/Footer.css";

const NewFooter = () => {
    const currentYear = new Date().getFullYear();
  return (
    <div className="newFooterSection">
        <div className=" container bg-background__footer">
         <div className="row footer_main">
          <div className="col-lg-5 pe-lg-5">
            <img src={DiscoveLogo} alt="img not found" className="img-fluid" />
            <p className="left-text text-white pe-lg-5 mt-3">
              Discovemail is your all-in-one email outreach platform. Find and connect with the people that matter to your business.
            </p>
          </div>

          <div className="col-md-6 col-lg-2 ">
            <div className="footer__product-area">
              <h3 className=" listTitle text-start mb-0">Product</h3>

              <ul className="d-flex flex-column newlist-group ps-0">
                <li className="list-item-none">
                  <a
                    href="/email-verifier"
                    target="_blank"
                  >
                    Bulk email
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/domain-verifier"
                    target="_blank"
                  >
                    Domain Verifier
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/email-from-name-and-domain"
                    target="_blank"
                  >
                    Email finder
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/search-company"
                    target="_blank"
                  >
                    Company search
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/campaign"
                    target="_blank"
                  >
                    Campaign
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
                    target="blank"
                  >
                    Chrome extension
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/our-addon"
                    target="blank"
                  >
                    Google Search
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ps-lg-5">
            <div className="footer__product-area">
              <h3 className=" listTitle text-start mb-0">Company</h3>

              <ul className="d-flex flex-column newlist-group ps-0">
                <li className="list-item-none">
                  <a
                    href="/affiliate-program"
                  >
                    Affiliate Program
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/claim"
                  >
                    Claim
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/our-records"
                  >
                    Our Records
                  </a>
                </li>
                <li className="list-item-none">
                  <a
                    href="/faq"
                  >
                    Faq
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-2 pe-lg-4">
            <h3 className="listTitle text-start mb-0">
              Location
            </h3>
            <div className="location">
              <a
                href="https://goo.gl/maps/gsWowKCVa8RXyhh5A"
                target="blank"
                className="addressText text-decoration-none text-align-left"
              >#265, 2nd Floor, 6th Main, 4th Cross Rd, Stage 2, BTM Layout, Bengaluru, Karnataka 560076</a>
            </div>
          </div>
          <div className="col-lg-12 mt-1">
            <div className="copyRightContainer d-flex flex-column  flex-lg-row justify-content-between align-items-center">
              <p className="copyRightText">© {currentYear} . Copyright and rights reserved</p>
              <div className="pt-2">
                <span className="legal-actions">
                  <a
                    href="https://www.instagram.com/discovemail/"
                    target="blank"
                  >
                    <img src={Instagram} alt="img" className="img-fluid me-3" />
                  </a>
                  <a href="https://www.linkedin.com/company/discovemail/" target="blank">
                    <img src={Linkedin} alt="img" className="img-fluid me-3" />
                  </a>
                  <a href="/privacy-policy" className="me-2">Our Privacy Policy</a>
                  <a href="/terms-of-service"> &bull; Terms and Conditions</a>
                </span>
              </div>
            </div>
          </div>
          <div className="container ">
      <img className="img-fluid" src={DiscoveBg} alt="bottomdesign" />
      </div>
        </div>
      </div>

      
    </div>
  )
}

export default NewFooter