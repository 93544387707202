import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  campaignId: null,
  campaignName: null,
  campaignStatus: null,
  campaignSummaryId:null,
  campaignContentSubject: '',
  campaignContentBody: '',
  campaignFollowupSubject: '',
  campaignFollowupUnsubscribe: false,
  campaignFollowupBody: '',
  campaignFollowupDays: 1,
  campaignContentPersonifyMailAttribute: 'first_name',
  campaignContentPersonifyMail: '',
  campaignContentAddSign: false,
  campaignSettingsWeekDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  campaignSettingsStartTime: '09:00',
  campaignSettingsEndTime: '17:00',
  campaignSettingsDelayTime: 30,
  campaignListId: null,
  campaignContentAttachments: []
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    campaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    campaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    campaignStatus: (state, action) => {
      state.campaignStatus = action.payload;
    },

    campaignSummaryId: (state, action) => {
      state.campaignSummaryId = action.payload;
    },
    campaignContentSubject: (state, action) => {
      state.campaignContentSubject = action.payload;
    },
    campaignContentBody: (state, action) => {
      state.campaignContentBody = action.payload;
    },
    campaignFollowupSubject: (state, action) => {
      state.campaignFollowupSubject = action.payload;
    },
    campaignFollowupUnsubscribe: (state, action) => {
      state.campaignFollowupUnsubscribe = action.payload;
    },
    campaignFollowupBody: (state, action) => {
      state.campaignFollowupBody = action.payload;
    },
    campaignFollowupDays: (state, action) => {
      state.campaignFollowupDays = action.payload;
    },
    campaignContentPersonifyMailAttribute: (state, action) => {
      state.campaignContentPersonifyMailAttribute = action.payload;
    },
    campaignContentPersonifyMail: (state, action) => {
      state.campaignContentPersonifyMail = action.payload;
    },
    campaignContentAddSign: (state, action) => {
      state.campaignContentAddSign = action.payload;
    },
    campaignSettingsWeekDays: (state, action) => {
      state.campaignSettingsWeekDays = action.payload;
    },
    campaignSettingsStartTime: (state, action) => {
      state.campaignSettingsStartTime = action.payload;
    },
    campaignSettingsEndTime: (state, action) => {
      state.campaignSettingsEndTime = action.payload;
    },
    campaignSettingsDelayTime: (state, action) => {
      state.campaignSettingsDelayTime = action.payload;
    },

    campaignListId: (state, action) => {
      state.campaignListId = action.payload;
    },
    resetPersonifyMail: (state) => {
      state.campaignContentPersonifyMail = initialState.campaignContentPersonifyMail;
    },
    resetFollowupSubject: (state) => {
      state.campaignFollowupSubject = initialState.campaignFollowupSubject;
    },
    resetFollowupBody: (state) => {
      state.campaignFollowupBody = initialState.campaignFollowupSubject;
    },
    resetFollowupUnsubscribe: (state) => {
      state.campaignFollowupUnsubscribe = initialState.campaignFollowupUnsubscribe;
    },
    resetFollowupDay: (state) => {
      state.campaignFollowupDays = initialState.campaignFollowupDays;
    },
    resetCampaign: () => {
      return initialState;
    },
    campaignContentAttachments: (state, action) => {
      state.campaignContentAttachments = action.payload;
    },
  },
})

export const {
  campaignId,
  campaignName,
  campaignStatus,
  campaignSummaryId,
  campaignContentSubject,
  campaignContentBody,
  campaignFollowupSubject,
  campaignFollowupUnsubscribe,
  campaignFollowupBody,
  campaignFollowupDays,
  campaignContentPersonifyMailAttribute,
  campaignContentPersonifyMail,
  campaignContentAddSign,
  campaignSettingsWeekDays,
  campaignSettingsStartTime,
  campaignSettingsEndTime,
  campaignSettingsDelayTime,
  resetPersonifyMail,
  resetFollowupSubject,
  resetFollowupBody,
  resetFollowupDay,
  campaignListId,
  resetCampaign,
  campaignContentAttachments,

} = campaignSlice.actions

export default campaignSlice.reducer