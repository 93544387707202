import React, { useEffect } from "react";
import PageNavbar from "./PageNavbar";
import "./NewSignIn.css";
import signUpBanner from '../../assets/signupBanner.webp'
import DiscoveLogo from '../../assets/discovemail_logo.png'
import verifiedTick from "../../assets/verified-tick-icon.svg";
import { FcGoogle } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useState } from "react";
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { RiKey2Line } from "react-icons/ri";
import { BsPerson } from "react-icons/bs";
import Helmet from "react-helmet";
import Navbar from "../../new_home/Navbar_1";
import NewPageNav from "./NewPageNavbar";
import PhoneInput from "react-phone-input-2";
import ContactUsSection from "../../components/contact/ContactUsSection";
import GoogleBtn from '../../assets/btn_google_signin_dark_normal_web.png';
import GoogleBtnPressed from '../../assets/btn_google_signin_dark_pressed_web.png';

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Please Enter Firstname";
  } else if (values.firstName[0].includes(" ")) {
    errors.firstName = "Must not include space at start";
  } else if (/^(?=.*?[0-9])/i.test(values.firstName)) {
    errors.firstName = "Must not contain number";
  } else if (/(?=.*?[#?!@$%^&*-])/i.test(values.firstName)) {
    errors.firstName = "Must not contain special characters";
  } else if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  } else if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter the Phone Number";
  } else if (values.phoneNumber.length < 8) {
    errors.phoneNumber = 'Phone number is not valid';
  } else if (!values.password) {
    errors.password = "Please Enter Password";
  } else if (!/^(?=.*?[a-z])/i.test(values.password)) {
    errors.password = "Must contain lowercase";
  } else if (!/^(?=.*?[0-9])/i.test(values.password)) {
    errors.password = "Must contain number";
  } else if (!/.{4,}/i.test(values.password)) {
    errors.password = "Must have 4 characters";
  }

  return errors;
};

function NewSignUp() {
  const navigate = useNavigate();
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [countryCode, SetCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentImage, setCurrentImage] = useState(GoogleBtn);

  function togglePasswordVisibility() {
    setHidePassword(!hidePassword);
  }

  const handleClickSignIn = () => {
    navigate("/signin");
  };

  const handleClickTermCondition = () => {
    navigate("/terms-of-service");
  }

  const handleClickPrivacyPolicy = () => {
    navigate("/privacy-policy");
  }

  const handleGoogleSignIn = async () => {
    setCurrentImage(GoogleBtnPressed);

    try {
      const response = await requestInstance.get(API_ENDPOINT.GOOGLE_SIGN_IN);
      window.open(response?.data?.url, '_self');
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("find_email"));
    if (user) {
      navigate("/dashboard");
    }
    window.scroll(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);
      setButtonDisabled(true);
      handleSignUp(values);
    },
  });

  const handlePhoneInput = (phonenumber, countryCode) => {
    SetCountryCode(countryCode.dialCode);
    setPhoneNumber(phonenumber.slice(countryCode.dialCode.length));

  }

  const handleSignUp = (values) => {
    let data = JSON.stringify({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password,
      country_code: '+' + countryCode,
      phone_number: phoneNumber
    });

    requestInstance
      .post(API_ENDPOINT.SIGNUP_API, data)
      .then((res) => {
        if (res.status == 201) {
          setCurrentUser(res.data.access);
          //console.log(res?.data?.message)
          toast.success(
            "Account created successfully, please verify your email."
          );
          navigate("/signin");
        }
      })
      .catch((err) => {
        //console.log(err)
        setLoading(false);
      });
  };

  const tabHead = "Discovemail-Signup"

  return (
    <>
      <Helmet>
        <title>{tabHead}</title>
      </Helmet>

      <section className="loginSection container-fluid px-0 mx-0 bg_color_dark">
        <div className="row px-0 mx-0 align-items-center">
          <div className="col-md-6 px-0 position-relative">
            <div>
              <img src={signUpBanner} alt="img not found" className="img-fluid" />
            </div>
            <div className="logo">
              <a href="/">
                <img src={DiscoveLogo} alt="img not found" className="img-fluid" />
              </a>
              <div className="signUpLeftContainer mt-md-5 pt-md-5 px-md-5 me-md-5">
                <h1 className="mainHeading pe-md-5 ps-md-2 ">Complimentary with your free account
                </h1>
              <h6 className="description">
                <img src={verifiedTick} alt="img not found" className="img-fluid ms-2" />
                &nbsp; 100 free credits every month
              </h6>
                <h6 className="description">
                <img src={verifiedTick} alt="img not found" className="img-fluid ms-2" />
                  &nbsp; Access to our Email Verifer
              </h6>
                <h6 className="description">
                <img src={verifiedTick} alt="img not found" className="img-fluid ms-2" />
                  &nbsp; Access to Email campaign 
              </h6>
                <h6 className="description">
                <img src={verifiedTick} alt="img not found" className="img-fluid ms-2" />
                  &nbsp; Access to Email Finder
              </h6>
                <h6 className="description">
                <img src={verifiedTick} alt="img not found" className="img-fluid ms-2" />
                  &nbsp; 24/7 support by phone, email & chat
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row justify-content-center py-5 px-md-5">
              <div className="col-10">
                <h1 className="loginTitle text-center mb-4">Sign up for a free account</h1>
                {/* <button className="btn btn-outline-light col-12 mx-sm-0 py-2">
                  <FcGoogle />&nbsp; Continue with google
                </button>
                <div class="orText text-center my-lg-4 my-3">
                  <span>Or</span>
                </div> */}
                <div className="">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row justify-content-center justify-content-md-between my-3">
                      <div className="col-lg-6 col-12">
                        <div className="form-label">
                          <div className="input-group">
                            <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              className="form-control py-3"
                              placeholder="First Name"
                            />
                          </div>
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="errormsg">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* ----LastName---- */}
                      <div className="col-lg-6 col-12">
                        <div className="form-label">
                          <div className="input-group">
                            {/* <span className="input-group-text login_icon">
                              <BsPerson />
                            </span> */}
                            <input
                              type="text"
                              id="lastName"
                              name="lastName"
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                              className="form-control w-100 py-3"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center justify-content-md-between my-3">
                      {/* ---Email--- */}
                      <div className="form-label col-lg-6 col-12 mb-3">
                        <div className="input-group">

                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="form-control w-100 py-3"
                            placeholder="Enter email address"
                          />
                        </div>

                        {formik.touched.email && formik.errors.email ? (
                          <div className="errormsg">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div className="col-lg-6 col-12">
                        <div className="form-label">
                          <div className="input-group w-100">
                            <PhoneInput
                              inputProps={{
                                name: "phoneNumber",
                                required: true,
                                placeholder: "Enter your Phone Number",
                                className: "form-control py-3 w-100",
                              }}
                              country={"in"}
                              name="phoneNumber"
                              enableSearch={true}
                              autoFormat={false}
                              countryCodeEditable={false}
                              value={formik.values.phoneNumber}
                              onChange={(value, data, event, formattedValue) => {
                                formik.setFieldValue('phoneNumber', value, true)
                                handlePhoneInput(value, data)
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                            <div className="errormsg">
                              {formik.errors.phoneNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* ----Password---- */}
                    <div className="form-label col-md-12 mb-3">
                      <div className="input-group w-100">
                        <input
                          type={hidePassword ? "password" : "text"}
                          id="password"
                          name="password"
                          title="Password must contain atleast 4 characters and atleast 1 number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          className="form-control password py-3"
                          placeholder="Password"
                        />
                        <span
                          className="input-group-text login_icon h-auto"
                          onClick={togglePasswordVisibility}
                        >
                          {" "}
                          {hidePassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </span>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="errormsg">{formik.errors.password}</div>
                      ) : null}
                    </div>

                    <p className="privacyTxt">
                      By using our application, you agree to our
                      <span
                        onClick={handleClickPrivacyPolicy}
                        role="button"
                        className="fw-bold login_navigation ms-2"
                      >
                        Privacy Policy
                      </span>
                      &nbsp;and
                      <span
                        onClick={handleClickTermCondition}
                        role="button"
                        className="fw-bold login_navigation ms-2"
                      >
                        Terms of Service.
                      </span>
                    </p>

                    <div>
                      <button
                        className="btn btn-disco w-100 py-3 mb-3"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Loader /> : <>Sign Up</>}
                      </button>
                    </div>

                    <p className="text-center signUpTxt">
                      Have an Account ?
                      <span
                        onClick={handleClickSignIn}
                        role="button"
                        className="fw-bold login_navigation ms-2"
                      >
                        Login
                      </span>
                    </p>
                  </form>
                </div>
                <div className='text-center' style={{ color: "#ccc"}}>OR</div>
                <div className="w-100 d-flex justify-content-center mt-3 cursorPointer">
                  <img onClick={handleGoogleSignIn} src={currentImage} alt="Sign in with Google" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default NewSignUp;

