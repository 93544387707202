

import EmailVerifyPic from '../assets/revampHomeassets/eVerifyPic.png';
import DomainVerifyPic from '../assets/revampHomeassets/dVerifyPic.png';
import FindContactPic from '../assets/revampHomeassets/findContact.svg';
import CampaignImg from '../assets/revampHomeassets/CampaignImg.svg';
import DiscoverImg from '../assets/revampHomeassets/DiscoverImg.svg';
import searchcompImg from '../assets/revampHomeassets/searchcompImg.svg';

export const toolkitData =[
    {
        imgSrc: EmailVerifyPic,
        heading: "Email verification that's easy-to-use",
        text1: "Effortless List Uploading: Directly upload your email lists from your computer.",
        text2: "Quick Identification of Invalid Addresses: Easily spot and remove outdated or incorrect emails",
        btnPath: "/email-verifier",
    },
    {
        imgSrc: DomainVerifyPic,
        heading: "Verify Your Domain With Confidence",
        text1: "Instant Search: Locate publicly accessible email addresses in seconds.",
        text2: "Advanced Filters: Refine your search by industry and reliability scores.",
        btnPath: "trailarea/domain-verifier",
    },
    {
        imgSrc: FindContactPic,
        heading: "Find Contacts by Name & Company",
        text1: "Comprehensive Data: Access email formats, web addresses, and verification status.",
        text2: "Streamlined Outreach: Save time and effort with accurate contact information.",
        btnPath: "trailarea/email-from-name-and-domain",
    },
    {
        imgSrc: searchcompImg,
        heading: "Search the company of your interest",
        text1: "Unlock valuable insights about companies of interest with our powerful tool.",
        text2: "Access comprehensive data to make informed decisions and drive business growth.",
        btnPath: "/search-company",
    },
    {
        imgSrc: CampaignImg,
        heading: "Run Campaigns with Us",
        text1: "Boost your response rates with DiscoveMail for targeted campaigns.",
        text2: "Unlock the potential of DiscoveMail Campaigns to optimize your efforts, increasing chances of eliciting higher responses.",
        btnPath: "/signup",
    },
    {
        imgSrc: DiscoverImg,
        heading: "Discover companies suiting your choice",
        text1: "Find organizations based on their names,headquarter location, Industry type and company strength.",
        text2: "Discovering vast arrays of companies meeting your criteria in your desired location.",
        btnPath: "/signup",
    },
]