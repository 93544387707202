import React, { useState } from 'react'
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import editIcon from '../../assets/edit.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { campaignFollowupDays, campaignFollowupSubject, campaignId, resetCampaign, resetFollowupDay } from '../../redux/slices/campaign/CampaignSlice';

const CampaignHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const campaignName = useSelector((state) => state.campaign.campaignName);
  const campaignStatus = useSelector((state) => state.campaign.campaignStatus);
  const [editValue, setEditValue] = useState(campaignName);
  const subject = useSelector((state)=>state?.campaign?.campaignContentSubject);
  const message = useSelector((state)=>state?.campaign?.campaignContentBody);
  const weekDays = useSelector((state)=>state?.campaign?.campaignSettingsWeekDays);
  const startTime = useSelector((state)=>state?.campaign?.campaignSettingsStartTime);
  const endTime = useSelector((state)=>state?.campaign?.campaignSettingsEndTime);
  const delayTime = useSelector((state)=>state?.campaign?.campaignSettingsDelayTime);
  const addSign = useSelector((state) => state?.campaign?.campaignContentAddSign);
  const attachments = useSelector((state) => state?.campaign?.campaignContentAttachments);

  const campaignIdCtx = useSelector((state)=>state?.campaign?.campaignId);
  const FollowupBody  = useSelector((state)=>state?.campaign?.campaignFollowupBody);
  const FollowupSubject  = useSelector((state)=>state?.campaign?.campaignFollowupSubject);
  const FollowupUnsubscribe  = useSelector((state)=>state?.campaign?.campaignFollowupUnsubscribe);
  const campaignListIdCtx = useSelector((state)=>state?.campaign?.campaignListId);
  const FollowupDays = useSelector((state) => state?.campaign?.campaignFollowupDays);
  




  const stepperData = [
    {
      title: 'Content  ',
      redirectionUrl: '/campaign/content',
      pageRoute: 'content'
    },
    {
      title: 'Audience  ',
      redirectionUrl: '/campaign/audience',
      pageRoute: 'audience'
    },
    {
      title: 'Settings ',
      redirectionUrl: '/campaign/settings',
      pageRoute: 'settings'
    },
    {
      title: 'Review  ',
      redirectionUrl: '/campaign/review',
      pageRoute: 'review'
    }
  ];

  

  const handleNavigation = (type) => {
    const indexOfReview = stepperData.findIndex(item => item.pageRoute === props.pageRoute);
    console.log('indexOfReview', indexOfReview)

    navigate(stepperData[type === 'back' ? (indexOfReview - 1) : (indexOfReview + 1)].redirectionUrl);
  }

  const handleEditChange = (event) => {
    setEditValue(event.target.value);
  };

  const handleCampaignRename = () => {
    const newBody = {
      'campaign_list_id': campaignIdCtx,
      'new_campaign_name': editValue ,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_RENAME, newBody)
      .then((res) => {
        // campaignDataFetch();
        setEdit(false);
        navigate('/campaign/all-campaigns');
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleContentData = async() => {
    if(props.pageRoute === 'content'){
      if (!campaignIdCtx) {
        const formData = new FormData();
        formData.append("email_subject", subject);
        formData.append("email_msg_body", message?.replaceAll('<p>', '<p style="margin: 0"/>'));
        formData.append("campaign_name", campaignName);
        formData.append("email_sign_status", addSign);
        for (let i = 0; i < attachments.length; i++) {
          formData.append(`${'files'}`, attachments[i]);
        }
        await requestInstance
            .post(API_ENDPOINT.CAMPAIGN_CREATE_CONTENT, formData)
            .then((response) => {
                dispatch(campaignId(response?.data?.data?.id));
                toast.success(response?.data?.message);
                handleNavigation('next')
                
            })
            .catch((error) => {
                console.error('Error sending data:', error);
            });
        }
        else{
          // const { selectedNumber, subject } = data?.followUpItems[data?.followUpItems?.length -1];
          if(FollowupSubject && FollowupDays){
            const followBody = {
              email_sub: FollowupSubject,
              email_body: FollowupBody?.replaceAll('<p>', '<p style="margin: 0"/>'),
              followup_days: parseInt(FollowupDays),
              is_signature: true,
              campaign_id: campaignListIdCtx,
              include_unsubscribe:FollowupUnsubscribe,
            };
        
            await requestInstance
              .post(`${API_ENDPOINT.CAMPAIGN_FOLLOWUP_MAIL}`, followBody)
              .then((response) => {
                toast.success(response?.data?.message);  
                handleNavigation('next')       
              })
              .catch((error) => {
                console.error('Error sending data:', error);
              });
          }else{
            handleNavigation('next')
          }
        }
    }
    else if(props.pageRoute === 'audience'){
      let newBody
      if(props?.callBackSelectAllLeads){
        newBody = {
          "campaign_name":campaignName,
          "all_lead_selected": props?.callBackSelectAllLeads
        }
      }else{
        newBody = {
          "campaign_name":campaignName,
          "leads_data": props?.callBackAudience,
        }
      }
      requestInstance
          .post(API_ENDPOINT.CAMPAIGN_CREATE_AUDIENCE, newBody)
          .then((response) => {
              localStorage.setItem('Review_Data_Id',response.data.data.campaign_name.id);
              toast.success(response?.data?.message);
              handleNavigation('next')
          })
          .catch((error) => {
              console.error('Error sending data:', error);
          });
    }
    else if(props.pageRoute === 'settings'){
      const newBody = {
        "start_time":startTime,
        "end_time":endTime,
        "timezone":"Asia/Kolkata",
        "average_delay_seconds":parseInt(delayTime),
        "send_days":weekDays,
      }
      requestInstance
          .post(API_ENDPOINT.CAMPAIGN_CREATE_SETTINGS, newBody)
          .then((response) => {
              toast.success(response?.data?.message);
              handleNavigation('next')
          })
          .catch((error) => {
              console.error('Error sending data:', error);
          });
    }
    else if(props.pageRoute === 'review'){
      const newBody = {
        "campaign_name":campaignName,
      }
      requestInstance
          .post(API_ENDPOINT.CAMPAIGN_LAUNCH_API, newBody)
          .then((response) => {
              toast.success(response?.data?.message);
              dispatch(resetCampaign());
              navigate('/campaign/all-campaigns');
          })
          .catch((error) => {
              console.error('Error sending data:', error);
          });
    }
};
  

  return (
  <>
    
      <div className="row">
      <div className='col-12 p-0'>
        <div className="breadcrumb_area d-flex align-items-center justify-content-between px-3">
          <h3 className="campaign-title">
              {edit ? (
                <div>
                  <BsFillArrowLeftCircleFill onClick={() => navigate('/campaign/all-campaigns')} className='cursorPointer' />
                  <input
                    className='py-1 fs-6 ms-2'
                    type="text"
                    value={editValue}
                    onChange={handleEditChange}
                    placeholder="Enter something..."
                  />
                  <button className=' ms-2 btn btn-danger btn-sm' onClick={handleCampaignRename}>Update</button>
                  <button className='ms-2 btn btn-secondary btn-sm' onClick={() => { setEdit(false); setEditValue(campaignName) }}>Cancel</button>                
                </div>) :
                <div>
                  <BsFillArrowLeftCircleFill onClick={() => navigate('/campaign/all-campaigns')} className='cursorPointer' />                
                  <span className='fs-6 fw-bold ms-2'>{editValue} {campaignStatus?.sending_status ==="Archived"?"":<img src={editIcon} alt='img not found' width='20px' className='img-fluid cursorPointer' onClick={() => setEdit(true)} />}</span>
                </div>
              }
          </h3>
          {props.pageRoute !== 'summary' ?
          <div className='customeStepper d-flex align-items-center gap-2 justify-content-end'>
            {
              stepperData.map((res, index) => (
                <div className='d-flex align-items-center justify-content-center cursorPointer' onClick={() => { navigate(res.redirectionUrl) }} key={index}>
                  <span className={(props.pageRoute === res.pageRoute ? 'indexIcon ' : '') + 'me-1 indexTxt d-flex align-items-center justify-content-center'}>{index + 1} </span>
                  {res.title}
                  <span className='arrowIcon ms-2'>{res.pageRoute !== 'review' && '>'}</span>
                </div>
              ))
            }
          </div>: <h5>Campaign Summary</h5>}
          {props.pageRoute !== 'summary' ?
          <div className='previewBtns d-flex align-items-center gap-4'>
            {props.pageRoute !== 'content' && <button className='backBtn border-0' onClick={() => { handleNavigation('back') }}>Back</button>}
            {(props.pageRoute !== 'review' && <button className='campaignBtnPrimary border-0' onClick={handleContentData}>Next</button>) || (props.pageRoute === 'review' && <button className='campaignBtnPrimary border-0' onClick={handleContentData}>Launch</button>)}
          </div>: null}
        </div>
      </div>
    </div>
    </>
  )
}

export default CampaignHeader;