import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import chromeImg from "assets/chromeImg.svg";
import excelimg from "assets/excelImg.svg";

function ExportSheet() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("exportAllList");

  const maxDate = new Date();
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month index starts from 0
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate > endDate) {
        setEndDate(selectedDate);
      }
      setStartDate(selectedDate);
      // console.log(formattedDate, "start date");
    } else {
      console.error("Invalid start date");
    }
  };

  const handleEndDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate < startDate) {
        setEndDate(startDate);
      } else {
        setEndDate(selectedDate);
      }
      // console.log(formattedDate, "end date");
    } else {
      console.error("Invalid end date");
    }
  };

  const handleExportToFile = () => {
    const token = JSON.parse(localStorage.getItem("find_email"));
    let url = `https://api.discovemail.com/user/get/excel/data`;
    if (startDate && endDate) {
      url =
        url +
        `?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`;
    }
    axios({
      url: url,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}  `,
      },
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;

        toast.success("Download successfull");
        link.setAttribute("download", "Discovemail.csv"); //or any other extension    document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err.response);
        // window.location.reload(true)
        if (err?.response?.status === 401) {
          navigate("/signin");
          toast.error(err?.response?.statusText);
        }
      });
  };

  return (
    <div>
      <div className="border border-light rounded-2 p-3 bg-white">
        <div className="d-flex align-items-start">
          <img src={chromeImg} alt="Chrome Extension" />
          <p className="fs-6 fw-medium mx-2">Add to Chrome Extension</p>
        </div>
        <p className="fs-6 fw-normal text-secondary mx-2">
          Add our extension to your chrome and 2x your marketing
        </p>
        <div className="d-flex justify-content-center">
          <a
            href="https://chromewebstore.google.com/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
            target="_blank"
            className="btn btn-disco rounded-2"
          >
            Chrome Extension
          </a>
        </div>
      </div>

      <div className="border border-light rounded-2 p-3 bg-white mt-4">
      <div className="d-flex align-items-start">
          <img src={excelimg} alt="Excel" />
          <p className="fs-6 fw-medium mx-2">Export to Spreadsheet</p>
        </div>
        <div className="d-flex justify-content-center">
      <button
        className="btn btn-disco rounded-2"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Export Now
      </button>
      </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Export to SpreadSheet
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="my-4">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="exportAllList"
                    name="exportAllList"
                    value="exportAllList"
                    checked={selectedOption === "exportAllList"}
                    onChange={handleOptionChange}
                  />
                  <label className="form-check-label" htmlFor="exportAllList">
                    Export All list
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="exportDateFilter"
                    name="exportDateFilter"
                    value="exportDateFilter"
                    checked={selectedOption === "exportDateFilter"}
                    onChange={handleOptionChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exportDateFilter"
                  >
                    Export with date Filter
                  </label>
                </div>

                {selectedOption === "exportDateFilter" && (
                  <div className=" mt-3">
                    <div>
                      <p className="mb-1">Start Date</p>
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd-MM-yyyy"
                        maxDate={maxDate}
                      />
                    </div>
                    <div>
                      <p className="mb-1">End Date</p>
                      <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd-MM-yyyy"
                        maxDate={maxDate}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleExportToFile}
                disabled={
                  selectedOption === "exportDateFilter" && startDate == null
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download mx-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportSheet;
