import React, { useEffect, useState } from 'react';
import noData from '../../assets/no-data.jpg';
import './AllLeads.css';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import ActionMenu from '../../utils/ActionMenu';
import EmailModal from '../../components/modal/EmailModal';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import { leadListData } from '../../redux/slices/lead/LeadSlice';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import EditLeadModal from './EditLeadModal';

const validate = (values) => {
    const errors = {};
  
    if (!values.email) {
      errors.email = "Please enter the email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }  
    return errors;
  };


const AllLeads = () => {
    const {leadName} = useParams()
    const navigate = useNavigate();
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    const [tableContent, setTableContent] = useState(true);
    const [leadData, setLeadData] = useState();
    const [currentRecord, setCurrentRecord] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastIndex, setLastIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState('');
    const [buttonState, setButtonState] = useState(true);
    const [customLeadNameInput, setCustomLeadNameInput] = useState(false);
    const [customLeadInput, setCustomLeadInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();

    const selectData = useSelector((state)=>state?.lead?.leadListData?.data)

    const [nextData, setNextData] = useState();
    const [prevData, setPrevData] = useState();
    const debouncedSearchValue = useDebounce(searchQuery, 900);

    const columns = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + lastIndex,
        },
        {
            name: 'First name',
            selector: row => row.first_name,
            sortable: true,
        },
        {
            name: 'Last name',
            selector: row => row.last_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone number',
            selector: row => row.phone_number,
            sortable: true,
        },
        {
            name: 'Company name',
            selector: row => row.company_name,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: row => row?.designation?.replace(/<[^>]*>/g, ''),
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.location,
            sortable: true,
        },
        {
            name: 'Company numbers',
            selector: row => row.company_numbers,
            sortable: true,
        },
        {
            name: 'Company emails',
            selector: row => row.company_emails,
            sortable: true,
            grow: 1,
        },
        {
            name: 'Profile urls',
            selector: row => ( 
            (row.profile_url ? <a href={row.profile_url} target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                 <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4"/>
                </svg>
            </a>:null)),
            grow: 0,
        },
        {
            name: 'Actions',
            selector: (row) => (
              <ActionMenu row={row} selectedRowData={handleDataFromChild} selectedRowEditData={handleEditDataFromChild} selectedRowDeleteData={handleDeleteDataFromChild} />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width:'76px',
    
        },
    ];
    

    const formik = useFormik({
        initialValues: {
          email: "",
          first_name: "",
          last_name:"",
          phone_number:"",
          company_name:"",
          designation:"",
          location:"",
          profile_url:"",
          company_website:"",
          company_numbers:"",
          company_emails:"",
        },
        validate,
        onSubmit: (values) => {
          setLoading(true);
          setButtonState(false);
          importPostData(values);
        },
      });



    // Callback function to receive data from the child
  const handleDataFromChild = (data) => {
    setCurrentRecord(data);
  };

  const handleEditDataFromChild = (data) => {
    setCurrentRecord(data);
  };


  const handleDeleteDataFromChild = (data) => {
    requestInstance
      .put(API_ENDPOINT.LEAD_DELETE + `/${data}`)
      .then((res) => {
        fetchUsers(currentPage);
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const fetchUsers = async (page) => {
    setLoading(true);
    let apiPath = '';
    if (leadName === '' || leadName === 'all-leads') {
      apiPath = API_ENDPOINT.LEAD_DATA_FETCH + `?&limit=${perPage}&offset=${(page - 1) * perPage}&search=${debouncedSearchValue}`;
    } else {
      apiPath =
        API_ENDPOINT.LEAD_DATA_FETCH +
        '?leads_name=' +
        leadName?.replaceAll('-', ' ') +
        `&limit=${perPage}&offset=${(page - 1) * perPage}&search=${debouncedSearchValue}`;
    }
    const response = await requestInstance.get(apiPath);
    setData(response.data.results);
    setTotalRows(response.data.count);
    setCurrentPage(page);
    setLastIndex((page - 1) * perPage);
    setNextData(response.data.next || null);
    setPrevData(response.data.previous || null);
    setLoading(false);
  };

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
  
      if (inputValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed');
      } else {
        setCustomLeadInput(inputValue);
        setErrorMessage(''); 
      }
    };

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
      };

      const leadsNameListData = () => {
        requestInstance
          .get(API_ENDPOINT.LEAD_NAME_LIST)
          .then((res) => {
            dispatch(leadListData(res.data));
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const finalSelect = selectedValue || customLeadInput;

      const importPostData = () => {
        let postData = {
            "source" : "manually",
            "leads_name" : finalSelect,
            "first_name":formik.values.first_name,
            "last_name": formik.values.last_name,
            "email":formik.values.email.toLowerCase(),
            "phone": formik.values.phone_number,
            "company_name": formik.values.company_name,
            "designation": formik.values.designation,
            "location": formik.values.location,
            "profile_url": formik.values.profile_url,
            "company_website":formik.values.company_website,
            "company_numbers": formik.values.company_numbers,
            "company_emails": formik.values.company_emails
        }

        formik.resetForm();
        requestInstance
          .post(API_ENDPOINT.LEAD_CONFIG, postData)
          .then((res) => {
            setSelectedValue('');
            formik.resetForm();
            fetchUsers(currentPage);
            toast.success(res.data.message);
            leadsNameListData();
            setButtonState(true);
            setSelectedValue('');
            setCustomLeadNameInput(false);
            setCustomLeadInput('');
            setErrorMessage('');

            setLoading(false);
          })
          .catch((err) => {
            setSelectedValue('');
            setButtonState(true);
            setCustomLeadNameInput(false);
            setCustomLeadInput('');
            toast.error(err);
            setLoading(false);
          });
      };
    
      const handleNextSubmit = () => {
            importPostData(); 
           
      };

  useEffect(() => {
    let ignore = false;

    const getTableData = async () => {
      setLoading(true);
      setPending(true);
      try {
        console.log('dsa');
        const response = await requestInstance.get(`${API_ENDPOINT.LEAD_DATA_FETCH}?leads_name=${leadName === 'all-leads' ? '' : leadName?.replaceAll('-',' ')}&limit=${perPage}&offset=${(currentPage - 1) * perPage}&search=${debouncedSearchValue}`);

        if (!ignore) {
          setData(response.data.results);
          setTotalRows(response.data.count);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
        setPending(false);
      }
    }

    getTableData();

    return () => {
      ignore = true;
    };
  }, [leadName, perPage, debouncedSearchValue, currentPage]);


  return (
    <>
         <div className="mt-3">
            <div className='d-flex justify-content-between align-items-baseline flex-column flex-md-row gap-3 gap-md-0'>
                <div>
                    <h6 className='fw-bold'>{leadName?.replaceAll('-',' ').toUpperCase()}</h6>
                </div>
                <div className='d-flex justify-content-end flex-column flex-md-row gap-md-5 gap-3 responsiveButton'>
                    <div>
                      <input type="search" className='form-control leadsSearchFilter' placeholder='Search Leads' id="" value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} />
                    </div>
                    <div>
                         <button className='btn btn-disco w-100' onClick={()=>{navigate('/lead/import-leads')}}>+ Import Leads</button>
                    </div>
                    <div>
                        <button className='btn btn-disco px-3' data-bs-toggle="modal" data-bs-target="#exampleModal1">+ New Leads</button>
                    </div>
                    
                </div>
            </div>
        </div>

        <EmailModal currentRecord={currentRecord} />


        {data && leadName ? 
        <div className='mt-3 mt-md-3 lead_table'>
            <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={(newPerPage) => setPerPage(newPerPage)}    
            onChangePage={(page) => setCurrentPage(page)}
            />
        </div>
        :
        <div className='d-flex justify-content-center container align-items-baseline centeredDiv'>
            <div>
                <img className='noDataImg' src={noData} alt="No Data Found" />
            </div>
            <div className=''>
                <h4 className='fw-bold'>No lead in this list</h4>
                <button className='btn btn-danger' onClick={()=>{navigate('/leads/import-leads')}}>Import Leads</button>
            </div>
        </div>  
      }
      
      <EditLeadModal data={currentRecord} fetchUsers={fetchUsers} />


    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Create a new lead</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form onSubmit={formik.handleSubmit}>
                <div className='container'>
                <div className='row'>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Destination List <sup className='text-danger'>*</sup></label>
                        <div className="input-group">
                <select
                    className="form-select"
                    id="select-example"
                    name="industry"
                    placeholder="Select destination list"
                    onChange={(e) => {
                      const finalSelectedValue = e.target.value;
                      if (finalSelectedValue === "custom") {
                        setCustomLeadNameInput(true);
                        setCustomLeadInput('');
                      }else{
                        setCustomLeadNameInput(false);
                        setCustomLeadInput(finalSelectedValue);
                      }
                    }}
                    value={customLeadInput}
                  >
                    <option value="">Select destination list</option>
                    {selectData?.map((val) => (
                      <option key={val.id} value={val.leads_name}>
                        {val.leads_name}
                      </option>
                    ))}
                    <option value="custom">Create a new list</option>
                  </select>

                  {customLeadNameInput && (
                    <div className="col-md-12 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Lead List Name"
                        value={customLeadInput}
                        id="custom_lead"
                        name="custom_lead"
                        onChange={handleInputChange}
                      />
                      {errorMessage && (
                        <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
                      )}
                    </div>
                  )}
              </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="exampleInputEmail1" class="form-label">Email address <sup className='text-danger'>*</sup></label>
                        <input type="email" id="exampleInputEmail1" aria-describedby="emailHelp" required  name="email"
                        placeholder='Enter your email'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.email} />
                         {formik.touched.email && formik.errors.email ? (
                        <div className="errormsg">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    
                
                    <div class="col-md-6 mb-3">
                        <label for="first_name" class="form-label">First name</label>
                        <input type="text" class="form-control" id="first_name" 
                        placeholder='Enter first name'
                        name="first_name"
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name} />
                         {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="errormsg">{formik.errors.first_name}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="last_name" class="form-label">Last name</label>
                        <input type="text" class="form-control" id="last_name" placeholder='Enter last name'
                        name="last_name"
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name} />
                         {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="errormsg">{formik.errors.last_name}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="Phone" class="form-label">Phone number</label>
                        <input type="tel" class="form-control" id="Phone" placeholder='Enter phone number' name="phone_number"
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_number} />
                         {formik.touched.phone_number && formik.errors.phone_number ? (
                        <div className="errormsg">{formik.errors.phone_number}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="company_name" class="form-label">Company name</label>
                        <input type="text" class="form-control" id="company_name" placeholder='Enter company name'
                        name='company_name'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_name} />
                         {formik.touched.company_name && formik.errors.company_name ? (
                        <div className="errormsg">{formik.errors.company_name}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="designation" class="form-label">Designation</label>
                        <input type="text" class="form-control" id="designation" placeholder='Enter designation' 
                        name='designation'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.designation} />
                         {formik.touched.designation && formik.errors.designation ? (
                        <div className="errormsg">{formik.errors.designation}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="location" class="form-label">Location</label>
                        <input type="text" class="form-control" id="location" placeholder='Enter location'  
                        name='location'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.location} />
                         {formik.touched.location && formik.errors.location ? (
                        <div className="errormsg">{formik.errors.location}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="profile_url" class="form-label">LinkedIn url</label>
                        <input type="url" class="form-control" id="profile_url" placeholder='Enter LinkedIn url'  name='profile_url'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.profile_url} />
                         {formik.touched.profile_url && formik.errors.profile_url ? (
                        <div className="errormsg">{formik.errors.profile_url}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="company_website" class="form-label">Company website</label>
                        <input type="url" class="form-control" id="company_website" placeholder='Enter company details' name='company_website'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_website} />
                         {formik.touched.company_website && formik.errors.company_website ? (
                        <div className="errormsg">{formik.errors.company_website}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="company_numbers" class="form-label">Company numbers</label>
                        <input type="tel" class="form-control" id="company_numbers" placeholder='Enter company number'
                        name='company_numbers'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_numbers} />
                         {formik.touched.company_numbers && formik.errors.company_numbers ? (
                        <div className="errormsg">{formik.errors.company_numbers}</div>
                      ) : null}
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="company_emails" class="form-label">Company emails</label>
                        <input type="email" class="form-control" id="company_emails" placeholder='Enter company email' name='company_emails'
                        className='form-control'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_emails} />
                         {formik.touched.company_emails && formik.errors.company_emails ? (
                        <div className="errormsg">{formik.errors.company_emails}</div>
                      ) : null}
                    </div>
                    
                    <div className='d-flex justify-content-end gap-3'>
                        <div>
                            <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" onClick={handleNextSubmit} disabled={buttonState === false || !formik?.values?.email?.length>0 || finalSelect ===  "" }>Submit</button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
                </div>
            </form>
        </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default AllLeads;