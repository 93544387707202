import React, { useState } from "react";
import { useFormik } from "formik";
import { CiMail } from "react-icons/ci";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import DiscoveLogo from '../../assets/discovemail_logo.png'
import "./Email.css";
import forgetBanner from '../../assets/forgetBanner.webp'
import PageNavbar from "../sigin/PageNavbar";
import { RiKey2Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import Footer from "../../new_home/Footer";
import Helmet from "react-helmet";
import ContactUsSection from "../../components/contact/ContactUsSection";

// Form Validation
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

function EmailLink() {
  const navigate = useNavigate();
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClick = (e) => {
    navigate("/signin");
  };

  // Function for Hode and Show Password
  // function togglePasswordVisibility() {
  //   setHidePassword(!hidePassword);
  // }
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);
      setButtonDisabled(true);
      Change(values);
    },
  });

  const Change = (values) => {
    let data = JSON.stringify({
      email: values.email,
    });

    // API for Password Reset
    requestInstance
      .post(API_ENDPOINT.SEND_RESET_LINK, data)
      .then((res) => {
        if (res.status == 201) {
          //console.log(res.data.data.email)
          setCurrentUser(res.data.access);
          toast.success(res.data.message);
          navigate("/signin");
          localStorage.setItem("ID", res.data.data.email);

        }
      })
      .catch((err) => {
        //toast.error(err?.message)
        //console.log(err)
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  const tabHead = "Discovemail-forget"

  return (
    <>
      <Helmet>
        <title>{tabHead}</title>
      </Helmet>
      <section className="reset_main bg_color_dark">
        <div className="container-fluid px-0 mx-0">
          <div className="row px-0 mx-0 align-items-center">
            <div className="col-md-6 px-0 position-relative">
              <div >
                <img src={forgetBanner} alt="img not found" className="img-fluid" />
              </div>
              <div className="logo">
                <a href="/">
                  <img src={DiscoveLogo} alt="img not found" className="img-fluid" />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-center py-5 px-md-5">
                <div className="col-lg-10 col-11">
                  <h1 className="title text-center mb-5">Forgot Password</h1>
                  <div className="">
                    <form onSubmit={formik.handleSubmit} className="">
                      <div className="form-label mb-lg-4 mb-3">
                        <div className="input-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="form-control py-3"
                            placeholder="name@example.com"
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="errormsg">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div>
                        <button
                          className="btn btn-disco w-100 mb-3"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? <Loader /> : <>Send Reset Link</>}
                        </button>
                      </div>
                      
                      <div className="text-center">
                        <a className="text-decoration-none loginText" href="/signin">
                          {" "}
                          Click here to return to<span role="button"
                            className="fw-bold login_navigation ms-2">Login</span> page.
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailLink;
