import React from "react";
import "./Homeravamp.css";
import { Typewriter, Cursor } from "react-simple-typewriter";
import opaqueBg from '../assets/homeassets/opaqueBg.svg';
import bgHero from '../assets/homeassets/bgHero.svg';

const NewHomeHero = () => {
  return (
    <div className="homeHero heroTopPadding">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-6 text-lg-start text-center leftHero ps-5">
            <h1 className="fw-normal fs-1 lh-base mt-5">
              <span className="text-white">Ultimate Email Platform</span>
              <br />
              <span className="text-white">Finder, Verifier &</span>
              <br />
              <span className="text-white">Campaigns</span>
              <br />
              <span className="gradient-text fw-bolder">
                <Typewriter
                  words={[
                    " Finder",
                    " Verifier",
                    "Discover Companies",
                    "Email From Name & Domain",
                    "Campaigns",
                  ]}
                  loop={true}
                  cursor3
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <span style={{ color: "#bc20b5" }}>
                <Cursor />
              </span>
            </h1>
            <p className="fs-5  fw-light">
              Ready to launch your email campaigns? start verifying your email
              list now.
              <br />
              It's time your emails start paying you off
            </p>
            <div className="row">
              <div
                className="d-flex col-lg-6  generalBtn rounded-2 mt-3 p-1"
                id="general"
              >
                <a
                  className="btn heroBtn text-white fs-5 fw-bolder w-100 px-5 py-2 "
                  href="/email-verifier"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6  rightHero overflow-hidden position-relative pb-3 d-none d-lg-block">
          <img src={opaqueBg} alt="pic1" className="img-fluid lower" />
          <img src={bgHero} alt="pic2" className="img-fluid  mid" />
            {/* <div className="lowest"></div>
            <div className="middle"></div> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default NewHomeHero;
