import React from "react";
import "./privacy.css";
import Navbar from "../../new_home/Navbar_1";
import Helmet from "react-helmet";
import Footer from "../../new_home/Footer";


function Privacy() {

  const tabHead = "Discovemail-policy"

  return (
    <div>
      <Helmet>
        <title>{tabHead}</title>
      </Helmet>
      <Navbar />
      <section className=" termsOfService bg_color_dark mt-md-5 py-md-5">
        <div className=' container py-md-5'>
          <div className='row px-md-4'>
            <div className='col-12 termsOfServiceCard bg_color_dark2 p-md-5 p-3'>
              <h1 className="linearGradientTitle"> DiscoveMail Privacy Policy</h1>
              <hr className='horizontalLine' />
              <p className='description'>
                This legal agreement is an electronic record in terms of the Indian
                Information Technology Act, 2000 and rules there under as applicable
                and the amended provisions about electronic records in various
                statutes as amended by the Indian Information Technology Act, 2000.
                This electronic record is generated by a computer system and does not
                require any physical or digital signatures.
              </p>

              <p className='description'>
                This legal document is published in accordance with the provisions of
                Rule 3 (1) of the Indian Information Technology (Intermediaries
                guidelines) Rules, 2011 and Rule 4 of the Information Technology
                (Reasonable security practices and procedures and sensitive personal
                data or information) Rules, 2011 of Information Technology Act, 2000
                amended through Information Technology Amendment Act, 2008 that
                require publishing the Terms of Use and practices for access and usage
                of any functional App.
              </p>

              <p className='description'>
                This App is created and operated by Vikgol Technologies Pvt. Ltd.,
                (hereinafter referred to as “We”, “Our”, and “Us”), operating under a
                brand name “discovemail .com” (“Brand name”). The creator of this Privacy
                Policy ensures a steady commitment to Your privacy with regard to the
                protection of your invaluable information that you may share across on
                this App. This privacy policy contains information about the Extension
                “discovemail .com” (“Application/App/Extension”).
              </p>

              <p className='description'>
                For the purpose of these Privacy Policy (“Privacy Policy”), wherever
                the context so requires, “We”, “Our”, and “Us” shall mean and refer to
                the App. “You”, “Your”, “Yourself”, “User” shall mean and refer to
                natural and legal individuals who shall be users of this App provided
                by us and who is competent to enter into binding contracts, as per
                Indian laws. “Third Parties” refer to any App or individual apart from
                the Users and the creator of this App.
              </p>

              <p className='description'>
                To provide You with Our uninterrupted use of the App, We may collect
                and, in some circumstances, disclose information about you with your
                permission. To ensure better protection of Your privacy, We provide
                this notice explaining Our information collection and disclosure
                policies, and the choices You make about the way Your information is
                collected and used.
              </p>

              <hr className='horizontalLine' />
              <h5 className='title'>
                This Privacy Policy shall be in compliance with the General Data
                Protection Regulation (GDPR) in effect from May 25, 2018, and any and
                all provisions that may read to the contrary shall be deemed to be
                void and unenforceable as of that date. If you do not agree with the
                terms and conditions of our Privacy Policy, including in relation to
                the manner of collection or use of your information, please do not use
                or access the App. If you have any questions or concerns regarding
                this Privacy Policy, you should contact our Customer Support Desk at
                contact@discovemail.com
              </h5>
              <hr className='horizontalLine' />

              <p className='description'>
                ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED
                TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE
                ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE
                AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS
                PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS
                CONTAINED WITHIN IT IN ANY MANNER.
              </p>

              <h2 className='title mt-md-5'>DISCLOSURE</h2>
              <p className='description'>
                Our application fully complies with the Google API Services User Data Policy. This includes strict adherence to the Limited Use requirements outlined in the policy. All user data accessed through Google APIs is utilized solely for the intended purposes within our app's functionalities. For easy reference and accessibility, you can find the complete Google API Services User Data Policy by following this <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noreferrer">Google API Services User Data Policy</a>. We encourage all users to review this policy to understand how their data is handled within our app.
              </p>

              <h2 className='title mt-md-5'>INFORMATION WE COLLECT</h2>
              <p className='description'>
                We are committed to respecting Your online privacy. We further
                recognize Your need for appropriate protection and management of any
                Personal Information You share with us. We may collect the following
                information
              </p>
              <p className='description'>
                <span>a. </span>Personal data including but not limited to Name, Date
                of Birth, Photos, Videos, Phone Number, Email ID, Gender, City,
                Address, Current location, Religion, Food/drink preference, Physical
                disability, Job status, Qualification, Height, Language, Salary,
                Marital status, Aadhar Card, Pan Card, Driving License, Passport,
                Voter Card.
              </p>
              <p className='description'>
                <span>b. </span>Tracking Information such as, but not limited to the
                IP address of your Mobile device, Computer Device and Device ID when
                connected to the Internet. This privacy policy also applies to data we
                collect from users who are not registered as members of this
                Extension, including, but not limited to, browsing behaviour, pages
                viewed etc. We also collect and store personal information provided by
                You from time to time on the App. We only collect and use such
                information from you that we consider necessary for achieving a
                seamless, efficient and safe experience, customized to your needs
                including:
              </p>

              <ul>
                <li className='description'>To enable the provision of services opted for by you;</li>
                <li className='description'>To enable the viewing of content in your interest;</li>
                <li className='description'>
                  To communicate the necessary account and service-related information
                  from time to time;
                </li>
                <li className='description'>
                  To allow you to receive quality customer care services and data
                  Collection;
                </li>
                <li className='description'>To comply with applicable laws, rules and regulations;</li>
              </ul>

              <p className='description'>
                Where any service requested by You involves a third party, such
                information as is reasonably necessary by Us to carry out Your service
                request may be shared with such third party. We also do use your
                contact information to send you offers based on your interests and
                prior activity and also to view the content preferred by you. We may
                also use contact information internally to direct its efforts for
                service improvement but shall immediately delete all such information
                upon withdrawal of your consent for the same through the 'unsubscribe'
                button or through an email to be sent to contact@vikgol.com
              </p>

              <p className='description'>
                To the extent possible, we provide You with the option of not
                divulging any specific information that you wish for us not to
                collect, store or use. You may also choose not to use a particular
                service or feature on the App and opt-out of any non-essential
                communications from the App.
              </p>

              <p className='description'>
                Further, transacting over the internet has inherent risks which can
                only be avoided by you following security practices yourself, such as
                not revealing account/login related information to any other person
                and informing our customer care team about any suspicious activity or
                where your account has/may have been compromised.
              </p>

              <h2 className='title mt-md-5'>OUR USE OF YOUR INFORMATION</h2>
              <p className='description'>
                The information provided by you shall be used to provide and improve
                the service for you and all users.
              </p>
              <ul>
                <li className='description'>To provide you with services on your request</li>
                <li className='description'>For maintaining an internal record.</li>
                <li className='description'>For enhancing the Services provided.</li>
                <li className='description'>To make your expense unforgettable on our platform</li>
                <li className='description'>
                  For maintaining record under the legal and statutory provisions.
                </li>
              </ul>
              <p className='description'>
                For more details about the nature of such communications, please refer
                to our Terms of Use. Further, your data and Sensitive Personal data
                may be collected and stored by Us for internal record.
              </p>

              <p className='description'>
                We use Your tracking information such as IP addresses, and or Device
                ID to help identify you and to gather broad demographic information
                and make further services available to you. We will not sell, licence
                or trade Your personal information. We will not share your personal
                information with others unless they are acting under our instructions
                or we are required to do so by law.
              </p>
              <p className='description'>
                Information collected via Our server logs includes Users' IP addresses
                and the App is used; this will be used to manage the web system and
                troubleshoot problems. We also use third-party analytics, tracking,
                optimization and targeting tools to understand how users engage with
                our App so that we can improve it and cater personalised content/ads
                according to their preferences.
              </p>

              <h2 className='title mt-md-5'>HOW INFORMATION IS COLLECTED</h2>
              <p className='description'>
                Before or at the time of collecting personal information, we will
                identify the purposes for which information is being collected. If the
                same is not identified to you, you have the right to request Us to
                elucidate the purpose of collection of said personal information,
                pending the fulfilment of which you shall not be mandated to disclose
                any information whatsoever. We will collect and use your personal
                information solely to fulfil those purposes specified by us, within
                the scope of the consent of the individual concerned or as required by
                law. We will only retain personal information as long as necessary for
                the fulfilment of those purposes. We will collect personal information
                by lawful and fair means and with the knowledge and consent of the
                individual concerned.
              </p>

              <p className='description'>
                Personal data should be relevant to the purposes for which it is to be
                used, and, to the extent necessary for those purposes, should be
                accurate, complete, and up-to-date.
              </p>

              <h2 className='title mt-md-5'>COOKIES</h2>
              <p className='description'>
                A cookie is a small file of letters and numbers that we store on your
                App or the hard drive of your device if you agree. We also use Device
                Advertising ID to track your performance on the App. By continuing to
                use our extension, you are agreeing to our use of cookies. Cookies
                contain information that is transferred to your hard drive. If you
                disable or refuse cookies, please note that some parts of this App may
                become inaccessible or not function properly. A list of the type of
                cookies we use is as follows;
              </p>

              <ul>
                <li className='description'>
                  Strictly necessary cookies. These are cookies that are required for
                  the operation of our App. They include, for example, cookies that
                  enable you to log into secure areas of our App, use a shopping cart
                  or make use of e-billing services.
                </li>
                <li className='description'>
                  Analytical/performance cookies. They allow us to recognize and count
                  the number of visitors and to see how visitors move around our App
                  when they are using it. This helps us to improve the way our App
                  works, for example, by ensuring that users are finding what they are
                  looking for easily.
                </li>
                <li className='description'>
                  Functionality cookies. These are used to recognize you when you
                  return to our App. This enables us to personalize our content for
                  you, greet you by name and remember your preferences (for example,
                  your choice of language or region).
                </li>
                <li className='description'>
                  Targeting cookies. These cookies record your visit to our App, the
                  pages you have visited and the links you have followed. We will use
                  this information to make our App and the advertising displayed on it
                  more relevant to your interests. We may also share this information
                  with third-parties for this purpose.
                </li>
              </ul>

              <p className='description'>
                Please note that third-parties (including, for example, advertising
                networks and providers of external services like web traffic analysis
                services) may also use cookies, over which we have no control. These
                cookies are likely to be analytical/performance cookies or targeting
                cookies. You can block cookies by activating the setting that allows
                you to refuse the setting of all or some cookies. However, if you use
                the settings to block all cookies (including essential cookies) you
                may not be able to access all or parts of our App.
              </p>
              <h2 className='title mt-md-5'>ANALYTICS TOOLS</h2>

              <p className='description'>
                We use Analytics such as Google and other Companies from time to time,
                to help us to understand how you make use of our content and work out
                how we can make things better. These cookies may follow all visitors
                of the App and collect anonymous data on where they have come from,
                which pages they visit, and how long they spend on the site. This data
                is then stored by Analytics Tools to create reports. These cookies do
                not store personal data or business data. The information generated by
                the cookie about the use of the App, including IP address, may be
                transmitted to, and stored by Analytics Tools on servers. Analytics
                Tool may use this information to evaluate the visitor's use of the
                App, compiling reports on App activity for us and providing other
                services relating to App activity and internet usage. The Analytics
                Tools may also transfer this information to third parties where
                required to do so by law, or where such third parties process the
                information on Analytics Tool's behalf. Analytics Tools will not
                associate any IP address' with any other data held by Analytics Tools.
                By using this App, all visitor's consent to the processing of data by
                Analytics Tools in the manner and for the purposes set out above. The
                Analytics Tool App contains further information about Analytics and
                please visit Analytics Tool's privacy policy pages for detailed
                understanding.
              </p>
              <h2 className='title mt-md-5'>PERMISSION AND ACCESS FROM THE DEVICE</h2>
              <p className='description'>
                The extension requires permission to access the following features:
              </p>
              <ul>
                <li className='description'>Location</li>
                <li className='description'>Device Storage</li>
                <li className='description'>Ability to make and receive calls.</li>
              </ul>

              <h2 className='title mt-md-5'>YOUR RIGHTS</h2>
              <p className='description'>
                Unless subject to an exemption, you have the following rights
                concerning your data:{" "}
              </p>
              <ul>
                <li className='description'>
                  The right to request a copy of your data which we hold about you;{" "}
                </li>
                <li className='description'>
                  The right to request for any correction to any personal data if it
                  is found to be inaccurate or out of date;
                </li>
                <li className='description'>
                  The right to withdraw Your consent to the processing at any time;
                </li>
                <li className='description'>The right to object to the processing of personal data;</li>
                <li className='description'>The right to complain about a supervisory authority. </li>
                <li className='description'>
                  The right to obtain information as to whether personal data are
                  transferred to a third country or an international organisation.
                </li>
              </ul>
              <p className='description'>
                Where you hold an account with any of our services, you are entitled
                to a copy of all personal data which we hold concerning you. You are
                also entitled to request that we restrict how we use your data in your
                account when you log in.
              </p>
              <h2 className='title mt-md-5'>CONFIDENTIALITY</h2>
              <p className='description'>
                You further acknowledge that the App may contain information that is
                designated confidential by us and that you shall not disclose such
                information without our prior written consent. Your information is
                regarded as confidential and therefore will not be divulged to any
                third party, unless if legally required to do so to the appropriate
                authorities. We will not sell, share, or rent your personal
                information to any third party or use your e-mail address for
                unsolicited mail. Any emails sent by us will only be in connection
                with the provision of agreed services, and you retain sole discretion
                to seek for discontinuation of such communications at any point in
                time.
              </p>

              <h2 className='title mt-md-5'>OTHER INFORMATION COLLECTORS</h2>
              <p className='description'>
                Except as otherwise expressly included in this Privacy Policy, this
                document only addresses the use and disclosure of information we
                collect from you. To the extent that you disclose your information to
                other parties, whether they are on our App or other sites throughout
                the Internet, different rules may apply to their use or disclosure of
                the information you disclose to them. To the extent that we use third
                party advertisers, they adhere to their privacy policies. Since we do
                not control the privacy policies of third parties, you are subject to
                ask questions before you disclose your personal information to others.
              </p>
              <h2 className='title mt-md-5'>OUR DISCLOSURE OF YOUR INFORMATION</h2>
              <p className='description'>
                We may host surveys for survey creators for our App who are the owners
                and users of your survey responses. We do not own or sell your
                responses. Anything you expressly disclose in your responses will be
                disclosed to survey creators. Please contact the survey creator
                directly to better understand how they might share your survey
                responses.
              </p>
              <p className='description'>
                Information collected will not be considered as sensitive if it is
                freely available and accessible in the public domain or is furnished
                under the Right to Information Act, 2005, any rules made thereunder or
                any other law for the time being in force.
              </p>
              <p className='description'>
                Due to the existing regulatory environment, we cannot ensure that all
                of your private communications and other personally identifiable
                information will never be disclosed in ways not otherwise described in
                this Privacy Policy. By way of example (without limiting and
                foregoing), we may be forced to disclose information to the
                government, law enforcement agencies or third parties. Therefore,
                although we use industry-standard practices to protect your privacy,
                we do not promise, and you should not expect, that your personally
                identifiable information or private communications would always remain
                private. We do however assure you that any disclosure of your
                personally identifiable information shall be personally intimated to
                you through an email sent to your provided email address.
              </p>
              <p className='description'>
                As a matter of policy, we do not sell or rent any personally
                identifiable information about you to any third party. However, the
                following describes some of the ways that your personally identifiable
                information may be disclosed:
              </p>

              <ul>
                <li className='description'>
                  External Service Providers: There may be several services offered by
                  external service providers that help you use our App. If you choose
                  to use these optional services, and in the course of doing so,
                  disclose information to the external service providers, and/or
                  permit them to collect information about you, then their use of your
                  information is governed by their privacy policy.
                </li>
                <li className='description'>
                  Law and Order: We cooperate with law enforcement inquiries, as well
                  as other third parties to enforce laws, such as intellectual
                  property rights, fraud and other rights. We can (and you authorise
                  us to) disclose any information about you to law enforcement and
                  other government officials as we, in our sole discretion, believe
                  necessary or appropriate, in connection with an investigation of
                  fraud, intellectual property infringements, or other activity that
                  is illegal or may expose us or you to legal liability.
                </li>
              </ul>

              <h2 className='title mt-md-5'>SECURITY</h2>
              <p className='description'>
                We treat data as an asset that must be protected against loss and
                unauthorized access. We employ many different security techniques to
                protect such data from unauthorized access by members inside and
                outside the Firm. We follow generally accepted industry standards to
                protect the Personal Information submitted to us and information that
                we have accessed. However, as effective as encryption technology is,
                no security system is impenetrable. Our Firm cannot guarantee the
                security of our database, nor can we guarantee that information you
                provide won't be intercepted while being transmitted to the Firm over
                the Internet.
              </p>
              <h2 className='title mt-md-5'>SEVERABILITY</h2>
              <p className='description'>
                Each paragraph of this Privacy Policy shall be and remain separate
                from and independent of and severable from all and any other
                paragraphs herein except where otherwise expressly indicated or
                indicated by the context of the agreement. The decision or declaration
                that one or more of the paragraphs are null and void shall not affect
                the remaining paragraphs of this privacy policy.
              </p>
              <h2 className='title mt-md-5'>AMENDMENT</h2>
              <p className='description'>
                Our Privacy Policy may change from time to time. The most current
                version of the policy will govern our use of your information and will
                always be on the App. Any amendments to this Policy shall be deemed as
                accepted by the User on their continued use of the App.
              </p>
              <h2 className='title mt-md-5'>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</h2>
              <p className='description'>
                To withdraw your consent, or to request the download or delete your
                data with us for any or all our services at any time, please email to
                contact@vikgol.com
              </p>
              <h2 className='title mt-md-5'>CONTACT US</h2>
              <p className='description'>
                If you have any questions or concerns regarding this privacy policy,
                you should contact us by sending an email to contact@discovemail.com
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Privacy;
