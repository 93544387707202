

import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {heroImgData, featuresData} from './NewUiData';
import FeatureCard from './FeatureCard';
import {Container, Row, Col} from 'react-bootstrap';

const WelcomePage = () => {
    var settings = {
        dots:true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
      };
  return (
    <div className='px-5 mt-1 bg-white paddingTop8rem'>
    <div className='sliderContainerLeft py-4 '>
    <Slider {...settings}>
          {heroImgData.map((ele) => (
            <img src={ele.pic} className="img-fluid" alt={ele.pic} />
          ))}
    </Slider>
    </div>

    <div className="welcomeFooter">
    <h3 >Get started with our</h3>
    <div>
      <FeatureCard featuresData={featuresData} />
    </div>

    </div>
    
    
    </div>
  )
}

export default WelcomePage