import React, { useEffect, useState, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import requestInstance from "../../../services/request";
import { API_ENDPOINT } from "../../../services/Api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  campaignFollowupBody,
  campaignFollowupDays,
  campaignFollowupSubject,
  campaignFollowupUnsubscribe,
  campaignId,
  campaignContentPersonifyMail,
  campaignContentPersonifyMailAttribute,
  resetPersonifyMail,
  campaignContentAddSign,
} from "../../../redux/slices/campaign/CampaignSlice";
import { DevTool } from "@hookform/devtools";

const personify_dropdown = [
  { key: "First Name", value: "first_name" },
  { key: "Last Name", value: "last_name" },
  { key: "Email", value: "email" },
  { key: "Phone", value: "phone_number" },
  { key: "Company", value: "company_name" },
  { key: "Company Website", value: "company_website_url" },
  { key: "Designation", value: "designation" },
];

export default function FollowUp({ templatesData }) {
  const [subjectText, setSubjectText] = useState("");
  const dispatch = useDispatch();
  const { register, control, handleSubmit, setValue, getValues } = useForm();
  // const [followupButton, setFollowupButton] = useState(false);
  // const [followupRequired, setFollowupRequired] = useState(false);
  const [messageField, setMessageField] = useState("");
  const [followupUnsubscribe, setFollowupUnsubscribe] = useState(false);
  const [addCursorType, setAddCursorType] = useState(null);
  const subId = useRef();
  const quillRef = useRef();
  const selectedAttribute = useSelector(
    (state) => state?.campaign?.campaignContentPersonifyMailAttribute
  );
  const personifyName = useSelector(
    (state) => state?.campaign?.campaignContentPersonifyMail
  );

  const [subjectCursorIndex, setSubjectCursorIndex] = useState(null);
  const [subjectFormIndex, setSubjectFormIndex] = useState(null);
  const [bodyCursorIndex, setBodyCursorIndex] = useState(null);
  const [bodyFormIndex, setBodyFormIndex] = useState(null);
  const [followUpTemplate, setFollowUpTemplate] = useState([]);

  const campaignIdCtx = useSelector((state) => state?.campaign?.campaignId);
  const campaignContentSubject = useSelector(
    (state) => state?.campaign?.campaignContentSubject
  );
  const campaignContentBody = useSelector(
    (state) => state?.campaign?.campaignContentBody
  );
  const attachments = useSelector(
    (state) => state?.campaign?.campaignContentAttachments
  );
  const campaignName = useSelector((state) => state.campaign.campaignName);
  const addSign = useSelector(
    (state) => state?.campaign?.campaignContentAddSign
  );
  const FollowupSubject = useSelector(
    (state) => state?.campaign?.campaignFollowupSubject
  );
  const FollowupUnsubscribe = useSelector(
    (state) => state?.campaign?.campaignFollowupUnsubscribe
  );
  const FollowupBody = useSelector(
    (state) => state?.campaign?.campaignFollowupBody
  );
  const FollowupDays = useSelector(
    (state) => state?.campaign?.campaignFollowupDays
  );
  const signature = useSelector((state) => state?.gAuth?.signValue?.signature);

  const campaignListIdCtx = useSelector(
    (state) => state?.campaign?.campaignListId
  );

  const numbers = Array.from(
    { length: 30 },
    (_, index) => index + 1 + (index === 0 ? " day" : " days")
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "followUpItems",
    defaultValues: {
      followUpItems: [{ selectedNumber: "1 day", subject: "", message: "" }],
    },
  });

  useEffect(() => {
    dispatch(campaignFollowupBody(messageField));
  }, [messageField]);

  useEffect(() => {
    dispatch(campaignFollowupSubject(subjectText));
  }, [subjectText]);

  const onSubmit = async (data) => {
    append({ selectedNumber: "1 day", subject: "", message: "" });

    if (!campaignIdCtx) {
      const formData = new FormData();
      formData.append("email_subject", campaignContentSubject);
      formData.append("email_msg_body", campaignContentBody?.replaceAll('<p>', '<p style="margin: 0"/>'));
      formData.append("campaign_name", campaignName);
      formData.append("email_sign_status", addSign);
      for (let i = 0; i < attachments.length; i++) {
        formData.append(`${"files"}`, attachments[i]);
      }

      await requestInstance
        .post(API_ENDPOINT.CAMPAIGN_CREATE_CONTENT, formData)
        .then((response) => {
          dispatch(campaignId(response?.data?.data?.id));
          toast.success(response?.data?.message);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
        });
    } else {
            if (data?.followUpItems?.length > 0) {
        const { selectedNumber, subject } =
          data?.followUpItems[data?.followUpItems?.length - 1];
        dispatch(campaignFollowupSubject(subject));
        dispatch(campaignFollowupBody(messageField));
        // dispatch(campaignFollowupDays(selectedNumber))

        const followBody = {
          email_sub: subjectText,
          email_body: messageField?.replaceAll('<p>', '<p style="margin: 0"/>'),
          followup_days: parseInt(selectedNumber),
          is_signature: true,
          campaign_id: campaignListIdCtx,
          include_unsubscribe: followupUnsubscribe,
        };

        await requestInstance
          .post(`${API_ENDPOINT.CAMPAIGN_FOLLOWUP_MAIL}`, followBody)
          .then((response) => {
            toast.success(response?.data?.message);
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          });
      }
    }
  };

  const insertTextAtCursor = () => {
    if (quillRef.current && selectedAttribute && addCursorType === "body") {
      const quill = quillRef.current.getEditor();
      const mergeFieldText = `{{${selectedAttribute}|${personifyName}}}`;
      const selection = quill.getSelection(true);
      quill.insertText(selection.index, mergeFieldText);
    }

    if (subId.current && selectedAttribute && addCursorType === "subject") {
      const currentSubject = subjectText || ""; // Ensure subject is initialized
      const mergeFieldText = `{{${selectedAttribute}|${personifyName}}}`;

      if (subjectCursorIndex || subjectCursorIndex == 0) {
        let itemValue = getValues(`followUpItems.${subjectFormIndex}.subject`);

        const newValue =
          itemValue.slice(0, subjectCursorIndex) +
          mergeFieldText +
          itemValue.slice(subjectCursorIndex);

        setValue(`followUpItems.${subjectFormIndex}.subject`, newValue);
      }

      const cursorPosition = subId.current.selectionStart || 0; // Get cursor position
      const newSubject = `${currentSubject.slice(
        0,
        cursorPosition
      )}${mergeFieldText}${currentSubject.slice(cursorPosition)}`;
      dispatch(campaignFollowupSubject(newSubject));
      setFollowUpTemplate(prevItems => {
        const updatedItems = [...prevItems];
        updatedItems[subjectFormIndex] = { ...updatedItems[subjectFormIndex], subject: newSubject };
        return updatedItems;
      });        }
    dispatch(resetPersonifyMail());
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
    }
  }, [quillRef]);

  return (
    <>
      <div className="row my-5  border-1" id="followup">
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => (
            <div className="row my-5 border-1 align-items-center" key={item.id}>
              <div className="col-md-5">
                <label className="pe-2">Follow up</label>
                <select
                  {...register(`followUpItems.${index}.selectedNumber`, {
                    required: true,
                  })}
                  defaultValue={item?.selectedNumber || "1 day"}
                  onChange={(e) =>
                    dispatch(campaignFollowupDays(e.target.value))
                  }
                >
                  {numbers.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
                <label className="ps-2">
                  after the previous email if no reply
                </label>
              </div>
              <div className="col-md-3">
                <select
                  id="select-example"
                  name="template"
                  placeholder="Select a template" 
                  className="form-select"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue !== "") {
                      const selectedTemplate = templatesData.find(
                        (template) => template.id === +selectedValue
                      );
                      setFollowUpTemplate(prevItems => {
                        const updatedItems = [...prevItems];
                        updatedItems[index] = selectedTemplate;
                        return updatedItems;
                      });    
                      setSubjectText(selectedTemplate?.subject);
                      setMessageField(selectedTemplate?.body);                
                    }
                    else {
                      setFollowUpTemplate(prevItems => {
                        const updatedItems = [...prevItems];
                        updatedItems[index] = {};
                        return updatedItems;
                      });
                    }
                  }}
                >
                 <option value="" >Select a template</option>
                  {templatesData?.map((val) => (
                    <option key={val.id} value={val.id}>
                      {val.template_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 d-flex justify-content-between">
                <button
                  className="btn test-mail-btn"
                  type="button" // Changed to "button"
                  data-bs-toggle="modal"
                  data-bs-target="#followupPersonify"
                  // onClick={() => {
                  //   setAddCursorType('subject');
                  //   setSubjectFormIndex(index);
                  // }}
                >
                  Personify Mail
                </button>
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => {
                    remove(index);
                    setFollowUpTemplate(prevItems => {
                      let updatedItems = [...prevItems];
                      updatedItems.splice(index, 1)
                      return updatedItems;
                    });
                  }}
                >
                  Remove
                </button>
              </div>
              <div className="col-12 mt-3">
                <div className="campaign-subject input-group">
                  <Controller
                    control={control}
                    name={`followUpItems.${index}.subject`}
                    defaultValue={""}
                    render={({ field }) => {
                      return (
                        <input
                          {...field}
                          className="campaign-subject__input form-control border-bottom-0"
                          ref={subId}
                          onClick={(e) => {
                            setSubjectCursorIndex(e.target.selectionStart);
                            setSubjectFormIndex(index);
                            setAddCursorType("subject");
                          }}
                          onBlur={(e) => {
                            setSubjectCursorIndex(e.target.selectionStart);
                            setSubjectFormIndex(index);
                            setAddCursorType("subject");
                          }}
                          placeholder="Subject"
                          value={followUpTemplate[index]?.subject || ""}
                          onChange={(e) => {
                            field.onChange(e);
                            dispatch(campaignFollowupSubject(e.target.value));
                            setAddCursorType("subject");
                            setFollowUpTemplate(prevItems => {
                              const updatedItems = [...prevItems];
                              updatedItems[index] = { ...updatedItems[index], subject: e.target.value };
                              return updatedItems;
                            });    
                            setSubjectText(e.target.value);       
                          }}
                        />
                      );
                    }}
                  />
                  {/* <input
                    type='text'
                    className='campaign-subject__input form-control border-bottom-0'
                    placeholder='Subject'
                    {...register(`followUpItems.${index}.subject`, { required: true })}
                    defaultValue={item?.subject}
                    onChange={(e)=>dispatch(campaignFollowupSubject(e.target.value))}
                  /> */}
                </div>
                <div className="campaign-message input-group">
                  <Controller
                    name={`campain-${index}message`}
                    control={control}
                    defaultValue={""}
                    render={({ field }) => {
                      return (
                        <ReactQuill
                          {...field}
                          ref={quillRef}
                          value={followUpTemplate[index]?.body || ""}
                          onChange={(value) => {
                            field.onChange(value);
                            setAddCursorType("body");
                            setMessageField(value);
                            setFollowUpTemplate(prevItems => {
                              const updatedItems = [...prevItems];
                              updatedItems[index] = { ...updatedItems[index], body: value };
                              return updatedItems;
                            });           
                            const fieldsCopy = [...fields];
                            fieldsCopy[index].message = value;
                          }}
                          onClick={(e) => {
                            setBodyCursorIndex(e.target.selectionStart);
                            setBodyFormIndex(index);
                            setAddCursorType("body");
                          }}
                          onBlur={(event) => {
                            const value = event?.target?.value;
                            setAddCursorType("body");
                            const fieldsCopy = [...fields];
                            fieldsCopy[index].message = value;
                            // dispatch(campaignFollowupBody(value));
                          }}
                          className="form-control campaign-message__textarea h-100 p-0 border-0"
                        />
                      );
                    }}
                  />

                  {addSign && (
                    <>
                      <div
                        className="signature-container"
                        dangerouslySetInnerHTML={{ __html:signature }}
                      ></div>
                      {/* Message to display when signature is added */}
                    </>
                  )}
                </div>

                <div className="mt-3 followupCheckboxDiv">
                  <input
                    type="checkbox"
                    className="followUpcheckbox"
                    name=""
                    id="followUp-checkbox"
                    checked={followupUnsubscribe}
                    onChange={(e) => {
                      setFollowupUnsubscribe((prev) => !prev);
                      dispatch(
                        campaignFollowupUnsubscribe(!followupUnsubscribe)
                      );
                    }}
                  />
                  <label htmlFor="followUp-checkbox" className="pl-3">
                    insert unsubscribe link
                  </label>
                </div>
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-between">
            <button
              className="btn btn-secondary w-25"
              type="submit"
              disabled={
                !campaignContentSubject?.length > 0 &&
                !campaignContentBody?.length > 0
              }
            >
              Add Follow up
            </button>
          </div>
        </form>
        {/* <DevTool control={control} /> */}
      </div>

      {/* modal starts */}

      <div
        className="modal fade"
        id="followupPersonify"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add an attribute...
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="my-4">
                <label className="input-group text-dark fw-semibold">
                  Attribute
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    dispatch(
                      campaignContentPersonifyMailAttribute(e.target.value)
                    )
                  }
                >
                  {personify_dropdown.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group mb-3">
                <label className="input-group text-dark fw-semibold">
                  Fallback
                </label>
                <input
                  type="text"
                  value={personifyName}
                  onChange={(e) =>
                    dispatch(campaignContentPersonifyMail(e.target.value))
                  }
                  className="form-control"
                  placeholder="Default value for empty attribute"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
              {/* <div className="form-text" id="basic-addon4">Default value for empty attribute</div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={insertTextAtCursor}
                disabled={personifyName === ""}
              >
                Insert
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* modal ends */}
    </>
  );
}
