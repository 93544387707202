import React from 'react'
import BlueIcon from '../assets/homeassets/BlueIcon.png';
import GreenIcon from '../assets/homeassets/GreenIcon.png';
import PinkIcon from '../assets/homeassets/PinkIcon.png';
import EmailCards from './EmailCards';

const EmailMarketing = () => {
  return (
    <div>
        <div className="emailMarketingDiv container-fluid text-center py-5">
        <h1 className='blueGradient my-4 fw-normal'>Your all-in-one email marketing platform</h1>
        <p className='fs-5 px-5 mx-5 text-secondary' >Discover the power of Discovemail and streamline your email marketing efforts. Our comprehensive platform offers a suite of tools to optimize your campaigns and achieve maximum results.</p>
        </div>
        <EmailCards />

    {/* <div className="container text-center py-5">
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="icon-box">
            <img 
              src={PinkIcon}
              alt="Email Icon" 
              className="icon-img mb-3"
            />
            <h5>Comprehensive email management and data-driven insights</h5>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="icon-box">
            <img 
              src={GreenIcon}
              alt="Deliverability Icon" 
              className="icon-img mb-3"
            />
            <h5>Enhanced deliverability and powerful campaign management</h5>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="icon-box">
            <img 
              src={BlueIcon}
              alt="Insights Icon" 
              className="icon-img mb-3"
            />
            <h5>Unleash the power of company insights</h5>
          </div>
        </div>
      </div>
    </div> */}
    
    </div>
  )
}

export default EmailMarketing